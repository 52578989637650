.box-containerA {
    width: auto; 
    height: auto; 
    background-color: #f0f0f0; 
    border: 1px solid #ccc; 
    padding-left: 100px; 
    padding-right: 100px; 
    padding-bottom: 150px; 
    text-align: left; 
}

.box-containerA h2 {
    color: #333; 
    display: block;  
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 45px;
    text-align: left;
}
    
  
.box-containerA p {
    line-height: 1.5;
    color: #333;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 22px;
}

/* Header styles */
header {
  background-color: rgba(58, 29, 0, .8);
  color: #fff;
  padding: 15px 5%;
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  position: fixed;
  width: 100%;
  z-index: 2;
  transition: padding 0.3s ease;
}

header a {
  font-size: clamp(14px, 1.25vw, 24px);
  text-align: left; 
  padding-left: clamp(10px, 2vw, 25px);
  flex-grow: 1; 
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  text-decoration: none;
  transition: font-size 0.3s ease;
}

button {
  background-color: rgba(21, 12, 4, 0);
  color: #fff;
  border: none;
  outline: none;
  padding: clamp(5px, 1vw, 30px) clamp(10px, 2vw, 30px);
  cursor: pointer;
  margin: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: clamp(16px, 1.75vw, 28px);
  text-align: right;
  transition: all 0.3s ease;
}

button:hover {
  text-decoration: underline;
  text-decoration-color: #2c4f40;
  text-underline-offset: 10px;
}

/* Footer styles */
footer {
  background-color: rgba(58, 29, 0, .8);
  text-align: center;
  padding: clamp(20px, 3vw, 40px) 5%;
}

footer img {
  width: clamp(50px, 7%, 120px);
  height: auto;
  margin-bottom: clamp(10px, 1.5vw, 20px);
}

footer a {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: clamp(14px, 1.75vw, 28px);
  text-decoration: none;
}

/* Hamburger menu for mobile */
.menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: #fff;
  padding: 5px;
  cursor: pointer;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  header {
    padding: 12px 4%;
  }
  
  button {
    padding: 5px 20px;
  }
}

@media screen and (max-width: 768px) {
  header {
    padding: 10px 3%;
  }
  
  header a {
    padding-left: 15px;
  }
  
  footer img {
    width: clamp(40px, 10%, 80px);
  }
}

@media screen and (max-width: 480px) {
  header {
    padding: 8px 15px;
  }

  .nav-links {
    display: none;
    width: 100%;
    order: 3;
    margin-top: 10px;
  }

  .nav-links.active {
    display: flex;
    flex-direction: column;
    background-color: rgba(58, 29, 0, 0.95);
    padding: 10px 0;
  }

  .nav-links a {
    padding: 12px 15px;
    text-align: center;
    font-size: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .nav-links a:last-child {
    border-bottom: none;
  }

  .menu-toggle {
    display: block;
  }

  .logo-image {
    height: 60px;
    width: auto;
  }

  button {
    font-size: 16px;
    padding: 8px 15px;
  }

  footer {
    padding: 20px 15px;
  }

  footer img {
    width: 60px;
  }

  footer a {
    font-size: 16px;
  }
};

.box-containerA li {
    line-height: 1.5;
    color: #333; 
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: clamp(16px, 2vw, 22px);
}
.about-image{
    width: 100%;
     height: auto;
     z-index: -1;
 }

 @media only screen and (max-width: 600px) {
    .box-containerA{
        padding: 20px;
    }
 }


    
