.box-containerH {
  width: 100%; 
  height: auto; 
  background-color: #f0f0f0; 
  border: 1px solid #ccc; 
  padding: clamp(20px, 5vw, 100px);
  text-align: left; 
}

.box-containerH h2 {
  color: #333; 
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: clamp(28px, 4vw, 45px);
  text-align: left;
  margin-bottom: clamp(20px, 3vw, 30px);
  line-height: 1.2;
}

.box-containerH p {
  line-height: 1.5;
  color: #333; 
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: clamp(16px, 2vw, 22px);
  margin: 0;
}

.second-p-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: clamp(30px, 4vw, 60px);
  margin-top: clamp(20px, 2.5vw, 25px);
}

.text-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: clamp(15px, 2.5vw, 25px);
}

.img-content {
  flex: 1;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.map-img {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: contain;
  padding: clamp(10px, 2vw, 20px);
}

/* Media Queries */
@media screen and (max-width: 1350px) {
  .box-containerH {
    padding: clamp(15px, 3vw, 30px);
  }
 
  .second-p-box {
    flex-direction: column-reverse;
    align-items: center;
    gap: clamp(20px, 3vw, 30px);
  }

  .text-content,
  .img-content {
    width: 100%;
    max-width: 800px; /* Prevent text from becoming too wide */
  }

  .map-img {
    width: 100%;
    max-width: 600px;
    height: auto;
    padding: clamp(10px, 2vw, 20px);
  }
}

@media screen and (max-width: 768px) {
  .box-containerH {
    padding: 15px;
  }

  .box-containerH h2 {
    margin-bottom: 15px;
  }

  .second-p-box {
    gap: 20px;
    margin-top: 20px;
  }

  .text-content {
    gap: 15px;
  }

  .map-img {
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .box-containerH {
    padding: 12px;
  }

  .map-img {
    width: 100%;
    padding: 5px;
  }
}