.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(58,29,0,1);
  z-index: 9999;
  opacity: 1;
  animation: fadeOut 1s ease 4s forwards; /* Fade out during the last second */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.overlay-logo {
  width: 300px;
  height: auto;
  opacity: 0;
  animation: fadeInOut 5s ease 1s forwards; /* Start at 1s, end at 5s */
}

.overlay-tagline {
  color: white;
  font-size: 24px;
  font-weight: 500;
  opacity: 0;
  animation: fadeInOut 5s ease 2.5s forwards; /* Start at 2s, end at 5s */
  letter-spacing: 1px;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}