/* Global Styles */
img {
    width: 100%;
    height: auto;
}

.home-container {
    position: relative;
}

.home-image {
    width: 100%;
    height: auto;
    z-index: -1;
}

.logo-image {
    position: absolute;
    top: 12%;  
    left: 4%;  
    height: 18%;  
    width: auto; 
    max-width: 150px;  
    max-height: 150px;  
    z-index: 0;  
}

.box-container {
    width: auto;
    height: auto;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 70px;
    text-align: left;
}

.box-container h2 {
    color: #333;
    display: block;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 45px;
    text-align: left;
}

.box-container p {
    line-height: 1.5;
    color: #333;
    display: block;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 22px;
}

.box-container a {
    text-decoration: none;
    color: #333;
    display: block;
    line-height: 1.5;
    font-family: "Montserrat", sans-serif;
    font-size: 2.5vw;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .logo-image {
        height: 15%;
        width: auto;
    }

    .box-container {
        padding: 40px;
    }

    .box-container h2 {
        font-size: 30px;
    }

    .box-container p {
        font-size: 18px;
    }

    .box-container a {
        font-size: 3vw;
    }
}

@media screen and (max-width: 480px) {
    .home-image {
        width: 100%;
        height: auto;
    }

    .logo-image {
        height: 10%;
        width: 8%;
    }

    .box-container {
        padding: 20px;
    }

    .box-container h2 {
        font-size: clamp(16px, 2vw, 22px);
    }

    .box-container p {
        font-size: clamp(16px, 2vw, 22px);
    }

    .box-container a {
        font-size: 4vw;
    }
}